import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const RedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const type = searchParams.get("type");

    if (token && type === "recovery") {
      // 토큰과 타입이 유효하면 계정 삭제 확인 페이지로 리다이렉트
      navigate(`/confirm-deletion?token=${token}&type=${type}`);
    } else {
      // 유효하지 않은 경우 홈페이지로 리다이렉트
      navigate("/");
    }
  }, [navigate, location]);

  return (
    <div
      style={{
        backgroundColor: "#1E1E1E",
        color: "#FFA500",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>리다이렉팅 중...</p>
    </div>
  );
};

export default RedirectHandler;
